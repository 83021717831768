@import "./variables.scss";
@import "./styles-base.scss";
@import "@danielmoncada/angular-datetime-picker/assets/style/picker.min.css";
@import "../node_modules/angular-calendar/css/angular-calendar.css";

:root {
  --primary-color: #{--mat-stepper-header-selected-state-icon-background-color};
  --secondary-color: #{--mat-full-pseudo-checkbox-selected-icon-color};
  --warn-color: #{--mdc-outlined-text-field-error-focus-outline-color};
}
